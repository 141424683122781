import React, { useState } from "react";
import styles from "./SelectPaymentStep2.module.scss";
import PaymentLevel from "./PaymentLevel";
import PaymentMastercard from "./PaymentMastercard";
import PaymentVisa from "./PaymentVisa";
import H2 from "../shared/h2/H2";
import InputMask from "react-input-mask";
import Button from "../shared/button/Button";
import "./Keyboard.scss";
import Keyboard from "../shared/keyboard/Keyboard";

const SelectPaymentStep2 = ({ paymentType, handleButton }) => {
  const [pin, setPin] = useState("");

  return (
    <div className={styles.container}>
      {paymentType === "level" && (
        <PaymentLevel styles={styles} onSelectPayment={null} />
      )}
      {paymentType === "mastercard" && (
        <PaymentMastercard styles={styles} onSelectPayment={null} />
      )}
      {paymentType === "visa" && (
        <PaymentVisa styles={styles} onSelectPayment={null} />
      )}
      <div className={styles.wrapper}>
        <H2 text="Введите PIN-код" />
        <div className={styles.wrapperInput}>
          <InputMask
            mask="9 9 9 9"
            maskChar="-"
            alwaysShowMask="false"
            value={pin}
          />
        </div>
        <div className={styles.wrapperButton}>
          <Button title="Далее" onClick={handleButton} />
        </div>
        <div className={styles.wrapperKeyboard}>
          <Keyboard onChange={input => setPin(input)} />
        </div>
      </div>
    </div>
  );
};

// {
//   margin: 0 auto;
//   display: block;
//   font-size: 40px;
//   outline: none;
//   letter-spacing: 64px;
// }
export default SelectPaymentStep2;
